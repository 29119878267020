.App {
  text-align: center;
  background-image: url('./background.jpg');
  
}
body {
  margin: 0;
 
  
}

.PrivacyPolicy {
   padding: 2vh 3vmax;
}

.App-logo {
  height: 10vmin;
  margin-top: 10vh;
  pointer-events: none;
}


.App-header {
  background-color: rgba(0, 0, 0, 0.75);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
}

.App-text {
  font-size: 3em;  
  color: #fff;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sub-text {
  font-size: 0.8em;  
  color: #fff;
  text-transform: uppercase;
  margin-top: 5vh;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.2em; 
}

.App-link {
  color: #61dafb;
}

.loading-container {
  margin-top: 2vh;
  position: relative;
  width: 25%;
  height: 1.5vh; /* Altura da barra */
  background-color: #ddd; /* Cor de fundo da barra */
  overflow: hidden;
}

.loading-bar {
  position: absolute;
  width: 75%;
  height: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 139, 0) 0%, rgba(2, 14, 22, 0.8) 50%, rgba(0, 0, 139, 0) 100%);
  background-size: 200% 100%;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
